<template>
  <v-card>
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="3">
          <v-autocomplete
            outlined
            dense
            v-model="record.SlpCode"
            :items="salesEmployees"
            item-text="SlpName"
            item-value="SlpCode"
            label="Sales Rep"
          ></v-autocomplete>
        </v-col>
        <!-- <v-col cols="3">
              <v-autocomplete
                outlined
                dense
                v-model="record.RouteCode"
                :items="routes"
                item-text="Name"
                item-value="id"
                label="Route"
              ></v-autocomplete>
            </v-col> -->

        <v-col cols="3">
          <DatePicker
            outlined
            dense
            @date="setDate"
            :myDate="record.DocDate"
            :title="`Date`"
          ></DatePicker>
        </v-col>

        <v-col cols="3">
          <v-autocomplete
            outlined
            dense
            v-model="record.Repeat"
            :items="repeatData"
            item-text="name"
            item-value="value"
            label="Repeat"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            label="Create Calls?"
            required
            v-model="record.CreateCall"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-textarea
            v-model="record.Dscription"
            label="Description"
            auto-grow
            dense
            outlined
            document_lines="1"
            row-height="15"
          ></v-textarea>
        </v-col>
        <v-col cols="6">
          <v-btn
            v-if="record.addDraft"
            class="mr-1 success"
            variant="success"
            @click="sendData"
          >
            <v-icon left dark>mdi-content-save</v-icon>Add Document
          </v-btn>
          <div v-else>
            <v-btn class="mr-1 primary" variant="primary" @click="sendData">
              <v-icon left dark>mdi-content-save</v-icon>Save
            </v-btn>
            <v-btn class="mr-1 primary" variant="primary" @click="saveDraft">
              <v-icon left dark>mdi-content-save</v-icon>Save as Draft
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
    },
    activeRoute: {
      type: Object,
    },
    id: {
      type: String,
    },
    outlets: {
      type: Array,
    },
  },
  data() {
    return {
      record: {},
      loader: false,
      menu2: false,
      menu3: false,
      search: null,
      searchInput: null,
      selectionType: "leaf",
      trueValue: true,
      editMode: false,
      // loading: false,
      salesEmployees: [],
      routes: [],
      repeatData: [
        { name: "Never", value: "N" },
        { name: "Daily", value: "D" },
        { name: "Weekly", value: "W" },
        { name: "Fortnightly", value: "F" },
        { name: "Monthly", value: "M" },
        { name: "Annually", value: "A" },
      ],
    };
  },
  watch: {
    initial: {
      handler: "setAssignment",
      immediate: true,
    },
  },
  methods: {
    setAssignment(val) {
      this.record = { ...val };
      console.log(this.record);
      this.record.isDraft = 0;
    },
    sendData() {
      const self = this;
      const data = {
        SlpCode: self.record.SlpCode,
        date: self.record.CallDate,
        Repeat: self.record.Repeat,
        route: self.outletsArray,
      };
      console.log(data);
      this.$store.commit("loader", true);
      this.$emit("data", self.record);
    },

    saveDraft() {
      this.record.isDraft = 1;
      this.sendData();
    },
    setDate(DocDate) {
      this.record.ObjType = 312;
      this.record.DocDueDate = DocDate;
      this.record.DocDate = DocDate;
    },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.salesEmployees = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getRoutes() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/routes_planning/${this.$route.params.id}`)
        .then((res) => {
          self.record.RouteCode = res.ResponseData.Code;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getEmployees();
    this.getRoutes();
  },
};
</script>

<style lang="scss" scoped></style>
