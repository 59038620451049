<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Route Assignments</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="primary" text outlined class="mr-5" @click="getAssignments">
          <v-icon>mdi-refresh-circle</v-icon>
        </v-btn>
        <v-btn color="primary" outlined small text @click="goBack">
          <v-icon left dark>mdi-skip-backward</v-icon>Back
        </v-btn>
      </v-toolbar>

      <!-- start of toolbar 2 -->
      <v-toolbar color="secondary" flat dense>
        <!-- <v-btn
          class="mr-1"
          to="route/1/user-assignment"
          outlined
          color="toolbarIcon"
          text
        >
          <v-icon left dark>mdi-plus</v-icon>New
        </v-btn> -->
        <v-btn color="green" outlined text @click="openModal">
          <v-icon left dark>mdi-plus</v-icon>Add Assignment
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          flat
          outlined
          solo
          dense
          label="search"
          style="width: 150px"
        ></v-text-field>
        <v-autocomplete
          v-model="Status"
          :items="Statuses"
          outlined
          dense
          label="Doc Status"
          item-text="text"
          item-value="value"
        ></v-autocomplete>
      </v-toolbar>
      <!-- end of toolbar 2 -->
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field
              filled
              dense
              readonly
              v-model="routeData.Name"
              label="Route Name"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              dense
              readonly
              v-model="routeData.Code"
              label="Route Code"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              dense
              readonly
              v-model="territory.descript"
              label="Region"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              dense
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="routeAssignments"
              :search="search"
            >
              <!-- <template v-slot:item.name="{ item }">
                <v-btn color="flatButton" text :to="`/routes/${item.id}`">{{
                  item.name
                }}</v-btn>
              </template>
              <template v-slot:item.bpartners="{ item }">{{
                item.outlets.length
              }}</template>
              <template v-slot:item.owner.name="{ item }">
                <v-btn text :to="`/users/${item.user_id}`">{{
                  item.owner.name
                }}</v-btn>
              </template> -->
              <template v-slot:item.index="{ index }">
                {{ index + 1 }}
              </template>

              <template v-slot:item.Date="{ item }">{{
                item.DocDueDate | moment("LLL")
              }}</template>
              <template v-slot:item.id="{ item }">
                <v-btn color="primary" text @click="openModal(item)">
                  <v-icon color="green">mdi-pen</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-dialog v-model="Dialog" persistent width="1000px">
        <v-card>
          <v-toolbar color="blue">
            <v-toolbar-title class="text-white">Assign User A Route</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="Dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- editor -->
          <editor :initial="itemsData" @data="save"></editor>
          <!-- end -->
        </v-card>
      </v-dialog>
      <v-dialog v-model="RemarksDialog" persistent width="850px">
        <v-card>
          <v-toolbar color="accent">
            <v-toolbar-title>Add Remarks</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="RemarksDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-col cols="4">
              <v-text-field
                outlined
                dense
                v-model="DocApproval.remarks"
                label="Remarks"
              ></v-text-field> </v-col
          ></v-card-text>
          <v-btn class="mr-1 primary" variant="primary" @click="DocSave">
            <v-icon left dark>mdi-content-save</v-icon>Save
          </v-btn>
        </v-card>
      </v-dialog>
    </v-card>

    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>
<script>
import Editor from "./components/create-editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      RemarksDialog: false,
      DocApproval: {},
      docData: {},
      itemsData: {},
      search: null,
      Statuses: [
        { value: 1, text: "Documents" },
        { value: 2, text: "Draft Documents" },
        { value: 3, text: "Approved not generated" },
      ],
      Status: 1,
      loading: false,
      headers: [
        { text: "No.", value: "index" },
        { text: "Route Name", value: "route.Name" },
        { text: "Sales Employee", value: "oslp.SlpName" },
        { text: "Date", value: "DocDueDate" },
        { text: "Action", value: "id" },
      ],
      routeAssignments: [],
      Dialog: false,
      outlets: [],
      territory: {},
      routeData: {},
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getRouteData",
      immediate: true,
    },
  },
  methods: {
    getRouteData(val) {
      this.id = val;
      const self = this;
      // api call
      this.$store
        .dispatch("get", `/route_outlets/${val}`)
        .then((res) => {
          self.routeData = res.ResponseData?.routeMaster;
          self.outlets = res.ResponseData?.routeOutlets;
          self.territory = res.ResponseData?.routeMaster?.territory;
        })
        .catch((err) => {
          console.log(err, "error");
          // this.$refs.snackbar.show(err, "red");
        });
      // end
    },
    getAssignments() {
      const self = this;
      this.Dialog = false;
      this.$store
        .dispatch(
          "get",
          `/route_assignments?route_id=${this.$route.params.id}&DocStatus=${this.Status}`
        )
        .then((res) => {
          self.routeAssignments = res.ResponseData.data;
        })
        .catch((err) => {
          console.log(err, "e");
        });
    },
    save(data) {
      this.docData = data;
      let URL = "/route_assignment/approval_templates";
      if (this.docData.addDraft == true) {
        URL = "/route_assignments";
      }
      const url = URL;
      console.log(url);
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          self.$store.commit("loader", false);
          self.$refs.snackbar.show("Documents need to be confirmed", "green");
          // this.getAssignments();
          this.Dialog = false;

          if (res.ResponseData) {
            self.RemarksDialog = true;
          }
        })
        .catch((err) => {
          this.Dialog = false;
          console.log(err);
          self.$store.commit("loader", false);
          // self.$refs.snackbar.show(err, "red");
        });
    },
    DocSave() {
      console.log(this.docData);
      const url = "/route_assignments";
      const self = this;
      this.docData.DocApproval = this.DocApproval;
      const data = this.docData;
      console.log("DATA", data);
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          self.$store.commit("loader", false);
          self.$refs.snackbar.show("Success", "green");
          this.getAssignments();
          this.RemarksDialog = false;
        })
        .catch((err) => {
          console.log(err);
          self.$store.commit("loader", false);
          self.$refs.snackbar.show(err, "red");
        });
    },
    openModal(item) {
      console.log(item.WddStatus);
      this.Dialog = true;
      if (item.WddStatus == "Y") {
        item.draftEntry = item.id;
        item.addDraft = true;
      } else {
        item.addDraft = false;
      }
      this.itemsData = { ...item };

      console.log(this.routeData);
    },

    goBack() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getAssignments();
  },
};
</script>

<style scoped></style>
